import './signup.css';

import SignupHTML from './signup.html';

import { Buffer } from 'buffer';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { navigateTo } from 'Modules/router.mjs';

function setupSignupField(field) {
	field.addEventListener('focus', (event) => {
		if (field.classList.contains('error')) {
			field.classList.remove('error');
		}
	});
}

export class SignupPage extends Page {
	constructor() {
		super();

		this.html = createHTML(SignupHTML);

		// Signp fields
		const signupFields = {
			firstName: { input: this.html.querySelector('#first_name_input'), isRequired: true },
			lastName: { input: this.html.querySelector('#last_name_input'), isRequired: true },
			email: { input: this.html.querySelector('#email_input'), isRequired: true },
			phone: { input: this.html.querySelector('#phone_input'), isRequired: false },
			password: { input: this.html.querySelector('#password_input'), isRequired: true },
		};

		// const passwordInput = this.html.querySelector('#password_input');
		const confirmPasswordInput = this.html.querySelector('#confirm_password_input');
		const signupButton = this.html.querySelector('#signup_button');

		for (let key in signupFields) {
			setupSignupField(signupFields[key].input);
		}

		setupSignupField(confirmPasswordInput);

		// Signup button
		let isSigningUp = false;

		function enableSignupButton() {
			isSigningUp = false;

			if (signupButton.classList.contains('disabled')) {
				signupButton.classList.remove('disabled');
			}
		}

		signupButton.addEventListener('click', (event) => {

			// Validate passwords match
			const passwordInput = signupFields.password.input.value;
			const isPasswordValid = passwordInput && passwordInput != '';
			const doPasswordsMatch = confirmPasswordInput.value === passwordInput;

			if (isPasswordValid && doPasswordsMatch) {
				if (confirmPasswordInput.classList.contains('error')) {
					confirmPasswordInput.classList.remove('error');
				}
			} else {
				if (!confirmPasswordInput.classList.contains('error')) {
					confirmPasswordInput.classList.add('error');
				}
			}

			// Validate user data
			const userData = {};

			let areFieldsInvalid = false;

			for (let key in signupFields) {
				const fieldData = signupFields[key];
				const value = fieldData.input.value;
				const isValueValid = fieldData.isRequired ? (value && value != '') : true;

				if (isValueValid) {
					userData[key] = value;

					if (fieldData.input.classList.contains('error')) {
						fieldData.input.classList.remove('error')
					}
				} else {
					areFieldsInvalid = true;

					if (!fieldData.input.classList.contains('error')) {
						fieldData.input.classList.add('error')
					}
				}
			}

			if (!isPasswordValid || !doPasswordsMatch || areFieldsInvalid) {
				return;
			}

			// Deny spam
			if (isSigningUp) return;
			isSigningUp = true;

			// Disable button
			if (!signupButton.classList.contains('disabled')) {
				signupButton.classList.add('disabled');
			}

			// Format payload
			// const payload = btoa(JSON.stringify(userData));
			const payload = Buffer.from(JSON.stringify(userData)).toString('base64');

			// Send signup request
			fetch(window.location.origin + `/api/account/signup`, {
				method: 'POST',
				body: payload,
			}).then(async (response) => {
				response.json().then((jsonResponse) => {
					if (!jsonResponse.success) {
						console.warn(`Signup failed, ${jsonResponse.message || '?'}`);

						return;
					}

					if (jsonResponse.sessionToken) {
						console.log('Signup success');

						localStorage.setItem('session_token', jsonResponse.sessionToken);

						navigateTo('/account');
					} else {
						console.warn(`Signup failed, no session token returned`);
					}
				}).catch((error) => {
					console.log('failed to decode response json');
					console.log(response.body);
					console.log(error);
				}).finally(enableSignupButton);
			}).catch((error) => {
				console.log('fetch error');
				console.log(error);
			}).finally(enableSignupButton);
		});
	}

	open() {
		const sessionToken = localStorage.getItem('session_token');

		if (sessionToken) {
			navigateTo('/account', true);

			return false;
		}
	}
}