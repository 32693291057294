import './account.css';

import AccountHTML from './account.html';

import { decodeJwt } from 'jose';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { navigateTo } from 'Modules/router.mjs';

export class AccountPage extends Page {
	constructor() {
		super();

		this.html = createHTML(AccountHTML);
	}

	open() {

		// Redirect to login if not logged in
		const sessionToken = localStorage.getItem('session_token');

		if (!sessionToken) {
			navigateTo(`/login`, true);

			return;
		}

		// Show account details
		const sessionData = decodeJwt(sessionToken);

		if (sessionData && sessionData.accountID) {
			navigateTo(`/u/${sessionData.accountID}`, true);
		}
	}
}