import { Buffer } from 'buffer';

import { setActiveModal } from 'Modals/modals.mjs';

export function getSessionToken() {
	return localStorage.getItem('session_token') || null;
}

export function attemptLogin(email, password) {
	return new Promise((resolve, reject) => {
		const payload = Buffer.from(JSON.stringify({ email: email, password: password })).toString('base64');

		fetch(window.location.origin + `/api/account/login`, {
			method: 'POST',
			body: payload,
		}).then((response) => {
			response.json().then((jsonResponse) => {
				if (!jsonResponse.success) {
					return reject(`Login failed, ${jsonResponse.message || '?'}`);
				}

				if (jsonResponse.sessionToken) {
					localStorage.setItem('session_token', jsonResponse.sessionToken);

					return resolve(jsonResponse)
				} else {
					return reject('No session token returned')
				}
			}).catch(reject);
		}).catch(reject);
	});
}

export function promptLogin(contextData) {

	// Ignore if already logged in
	const sessionToken = getSessionToken();

	if (sessionToken) {
		if (contextData && contextData.callback) {
			contextData.callback(sessionToken);
		}

		return false;
	}

	// Show login prompt
	setActiveModal('login', contextData);

	return true;
}

function defaultLoginRequiredCallback(event, sessionToken) {
	if (sessionToken) {
		navigateLink(event.target);
	}
}

export function setupLoginRequiredButton(button, promptText, callback) {
	button.addEventListener('click', (event) => {
		event.preventDefault();

		// Prompt if not logged in
		if (promptLogin({
			text: promptText,

			callback: (sessionToken) => {
				(callback || defaultLoginRequiredCallback)(event, sessionToken);
			},
		})) {
			return;
		};
	});
}