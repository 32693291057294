import './modals.css';

import ModalsHTML from './modals.html';

import { createHTML } from 'Modules/html.mjs';

import { PayModal } from 'Modals/pay/pay.mjs';
import { LoginModal } from 'Modals/login/login.mjs';

const baseHTML = createHTML(ModalsHTML);

baseHTML.addEventListener('click', (event) => {
	if (event.target !== baseHTML) return;

	setActiveModal();
})

let modalCache = {};
let activeModal;

export const MODAL_INDEX = {
	'pay': PayModal,
	'login': LoginModal,
}

export function getModal(modalName) {

	// Get cached page
	let modal = modalCache[modalName];

	if (!modal) {

		// Validate modal class
		const modalClass = MODAL_INDEX[modalName];

		if (!modalClass) {
			console.warn(`Failed to create modal ${modalName}, modal class not found`);

			return null;
		}

		// Create modal
		modal = new modalClass();
		modal.baseHTML = baseHTML;
		modalCache[modalName] = modal;
	}

	return modal;
}

export function setActiveModal(modalName, contextData) {
	
	// Validate modal
	const modal = modalName ? getModal(modalName) : null;

	// Close modal
	if (activeModal) {
		activeModal.close(contextData);

		if (activeModal.html && activeModal.html.parentElement) {
			activeModal.html.remove();
		}
	}

	activeModal = modal;

	// Open modal
	if (activeModal) {
		activeModal.open(contextData);
	}

	// Show base html
	if (baseHTML.children.length > 0) {
		if (!baseHTML.parentElement) {
			document.body.appendChild(baseHTML);
		}
	} else {
		baseHTML.remove();
	}
}

export function hideModal(modalName) {
	if (!modalName) return;

	const modal = modalName ? getModal(modalName) : null;

	if (modal && activeModal === modal) {
		setActiveModal();
	}
}

window.setActiveModal = setActiveModal;