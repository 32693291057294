const job_cache = {};

export function getJobFromID(jobID) {
	return new Promise((resolve, reject) => {
		let jobData = job_cache[jobID];

		if (jobData) {
			return resolve(jobData);
		}

		return fetch(`/api/jobs/getJobData?jid=${jobID}`, { method: 'GET' }).then((response) => {
			return response.json().then((jsonResponse) => {
				if (!jsonResponse) return;

				if (jsonResponse.success && jsonResponse.data) {
					jobData = jsonResponse.data;
					jobData.jobID = jobID;

					job_cache[jobID] = jobData;

					return resolve(jobData);
				}
			}).catch(reject);
		}).catch(reject);
	});

	// let jobData = job_cache[jobID];

	// if (jobData) {
	// 	return new Promise((resolve, reject) => {
	// 		return resolve(jobData);
	// 	});
	// }

	// return fetch(`/api/jobs/getJobData?jid=${jobID}`, { method: 'GET' }).then((response) => {
	// 	return response.json().then((jsonResponse) => {
	// 		if (!jsonResponse) return;

	// 		if (jsonResponse.success && jsonResponse.data) {
	// 			jobData = jsonResponse.data;
	// 			jobData.jobID = jobID;

	// 			console.log(jsonResponse.data);

	// 			job_cache[jobID] = jobData;

	// 			return jobData;
	// 		}
	// 	}).catch((error) => {
	// 		console.log('failed to decode json');
	// 		console.log(error);
	// 	});
	// });
}