import './new.css';

import JobsNewHTML from './new.html'
import SelectionItemHTML from './selection_item.html';

import { Buffer } from 'buffer';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { navigateTo } from 'Modules/router.mjs';
import { DropdownTest } from './dropdown_test.mjs';
import { availableRoles } from 'Data/roles.mjs';

function setupInputField(field) {
	field.addEventListener('focus', (event) => {
		if (field.classList.contains('error')) {
			field.classList.remove('error');
		}
	});
}

export class JobsNewPage extends Page {
	constructor() {
		super();

		this.html = createHTML(JobsNewHTML);

		// Role input
		const roleInput = this.html.querySelector('#role_input');
		const dropdownTest = new DropdownTest();

		setupInputField(roleInput);

		function addSelectedRole(role) {
			roleInput.value = '';

			// Ignore if already in selection
			for (let child of roleInput.parentElement.children) {
				if (child.getAttribute('role') === role) return;
			}

			// Add role to selection
			const div = createHTML(SelectionItemHTML);
			div.setAttribute('role', role);
			div.innerHTML = role;

			div.addEventListener('click', () => {
				div.remove();
			});

			roleInput.parentElement.insertBefore(div, roleInput);
		}

		roleInput.addEventListener('focus', (event) => {
			dropdownTest.open(roleInput, addSelectedRole, availableRoles);
		});

		roleInput.addEventListener('input', (event) => {
			dropdownTest.setFilterText(roleInput.value);
		});

		roleInput.addEventListener('blur', (event) => {
			dropdownTest.clearParent(roleInput);
		});

		// Preview button
		const inputFields = {
			title: { input: this.html.querySelector('#title_input') },
			location: { input: this.html.querySelector('#location_input') },
			description: { input: this.html.querySelector('#description_input') },
			pay: { input: this.html.querySelector('#pay_input') },
			deadline: { input: this.html.querySelector('#deadline_input') },
		};

		for (let key in inputFields) {
			setupInputField(inputFields[key].input);
		}

		this.html.querySelector('.preview_button').addEventListener('click', () => {

			// Setuo job data
			const jobData = {
				roles: [],
				postTime: new Date().getTime(),
			};

			// Field job data fields
			let areFieldsInvalid = false;

			for (let key in inputFields) {
				const fieldData = inputFields[key];
				const value = fieldData.input.value;
				const isValidValue = value && value != '';

				if (isValidValue) {
					jobData[key] = value;

					if (fieldData.input.classList.contains('error')) {
						fieldData.input.classList.remove('error');
					}
				} else {
					areFieldsInvalid = true;

					if (!fieldData.input.classList.contains('error')) {
						fieldData.input.classList.add('error');
					}
				}
			}

			// Add roles
			for (let child of roleInput.parentElement.children) {
				const role = child.getAttribute('role');

				if (role) {
					jobData.roles.push(role);
				}
			}

			// Validate roles
			const areRolesValid = jobData.roles.length > 0;

			if (areRolesValid) {
				if (roleInput.classList.contains('error')) {
					roleInput.classList.remove('error');
				}
			} else {
				if (!roleInput.classList.contains('error')) {
					roleInput.classList.add('error');
				}
			}

			// Reject if not valid
			if (areFieldsInvalid || !areRolesValid) {
				return;
			};

			// Go to job preview
			const encodedJobData = Buffer.from(JSON.stringify(jobData)).toString('base64');

			navigateTo(`/j?pjd=${encodedJobData}`);
		});
	}

	open() {

		// Redirect to login if not logged in
		const sessionToken = localStorage.getItem('session_token');

		if (!sessionToken) {
			navigateTo(`/login`, true);

			return;
		}
	}
}