import './job.css';

import JobHTML from './job.html'

import { Buffer } from 'buffer';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { Spinner } from 'Classes/spinner/spinner.mjs';

import { navigateTo } from 'Modules/router.mjs';
import { getJobFromID } from 'Modules/job_manager.mjs';
import { formatJobRoles } from 'Data/roles.mjs';
import { promptLogin } from 'Modules/session.mjs';

import { JobApplyPage } from './apply/apply.mjs';
import { JobsNewPage } from './new/new.mjs';
import { JobsOffersPage } from './offers/offers.mjs';
import { JobsOfferedPage } from './offered/offered.mjs';

const currencyFormatter = new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' });
const dateFormatter = new Intl.DateTimeFormat('en-IE', {
	dateStyle: 'long',
	timeStyle: 'short',
	hour12: true,
});

export class JobPage extends Page {
	constructor() {
		super();

		this.html = createHTML(JobHTML);

		this.loadingSpinner = new Spinner();

		const jobsNewPage = new JobsNewPage();
		const jobsApplyPage = new JobApplyPage();

		this.isDynamicPage = true;
		this.childPages = {
			'offers': new JobsOffersPage(),
			'offered': new JobsOfferedPage(),

			'n': jobsNewPage,
			'new': jobsNewPage,

			'a': jobsApplyPage,
			'apply': jobsApplyPage,
		}

		// Get buttons
		this.applyButton = this.html.querySelector('#apply_button');
		this.submitButton = this.html.querySelector('#submit_button');
		this.bookmarkButton = this.html.querySelector('#bookmark_button');
		this.editButton = this.html.querySelector('#edit_button');

		// Apply button
		this.applyButton.addEventListener('click', (event) => {

			// Validate job data
			const jobData = this.jobData;

			if (!jobData || !jobData.jobID) {
				console.warn(`Failed to submit, job data not found`);

				return;
			}
			
			// Prompt if not logged in
			if (promptLogin({ text: 'Log in to apply for this job' })) {
				return;
			}

			// Load job data from ID
			const pathSegments = window.location.pathname.split('/');
			const pathQuery = new URLSearchParams(window.location.search);
			const pathJobID = pathQuery.get('id');

			if (pathSegments[2] === jobData.jobID || pathJobID === jobData.jobID) {
				// User selected apply while on the job page
				navigateTo(`/j/${jobData.jobID}/apply`);
			} else {
				// User selected apply while not on the job page (eg jobs list page)
				navigateTo(`/j/${jobData.jobID}`);
			}
		});

		// Submit button
		let lastSubmitTime

		this.submitButton.addEventListener('click', (event) => {

			// Validate job data
			const jobData = this.jobData;

			if (!jobData) {
				console.warn(`Failed to submit, job data not found`);

				return;
			}

			// Deny spam
			const now = performance.now();

			if (this.isSubmitting) return;
			if (lastSubmitTime && now - lastSubmitTime < 1_000) return;
			lastSubmitTime = now;

			// Disable button
			this.isSubmitting = true;

			if (!this.submitButton.classList.contains('disabled')) {
				this.submitButton.classList.add('disabled');
			}

			// Submit preview job data
			fetch(window.location.origin + '/api/jobs/submitOffer', {
				method: 'POST',
				body: JSON.stringify({ jobData: jobData }),
				headers: {
					'Content-Type': 'application/json',
				},
			}).then((response) => {
				response.json().then((jsonResponse) => {
					if (!jsonResponse) return;

					if (jsonResponse.success && jsonResponse.jobID) {
						jobData.jobID = jsonResponse.jobID;

						navigateTo(`/j/${jobData.jobID}`);
					}
				});
			}).finally(() => {

				// Enable button
				this.isSubmitting = false;

				if (this.submitButton.classList.contains('disabled')) {
					this.submitButton.classList.remove('disabled');
				}
			});
		});

		// Bookmark button
		const bookmarkButton = this.bookmarkButton;

		let isBookmarking = false;

		bookmarkButton.addEventListener('click', (event) => {

			// Ignore if busy
			if (isBookmarking) return;

			// Prompt if not logged in
			if (promptLogin({ text: 'Log in to bookmark this job' })) {
				return;
			}

			// Validate job ID
			const jobID = this.jobData && (this.jobData.jobID || this.jobData.id) || null;

			if (!jobID) {
				console.warn(`Failed to bookmark, job ID not found`);

				return
			}

			// Disable button
			isBookmarking = true;

			if (!bookmarkButton.classList.contains('disabled')) {
				bookmarkButton.classList.add('disabled');
			}

			// Request add
			fetch(window.location.origin + `/api/bookmarks/add`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					auth: localStorage.getItem('session_token'),

					category: 'jobs',
					referenceID: jobID,
				}),
			}).then((response) => {
				response.json().then((jsonResponse) => {
					if (!jsonResponse) return;

					console.log(jsonResponse);
				}).catch((error) => {
					console.log('failed to decode json');
					console.log(error);
				});
			}).finally(() => {

				// Enable button
				if (bookmarkButton.classList.contains('disabled')) {
					bookmarkButton.classList.remove('disabled');
				}

				isBookmarking = false;
			});
		});

		// Edit button
		const editButton = this.editButton;

		editButton.addEventListener('click', (event) => {
			navigateTo(`/j/n`);
		});
	}

	open() {

		// Show spinner
		this.setParent();
		this.loadingSpinner.setParent(document.body);

		// Load job data from url
		const pathSegments = window.location.pathname.split('/');
		const jobID = pathSegments[2];

		if (jobID) {
			return getJobFromID(jobID).then((jobData) => {
				this.setParent();

				if (jobData) {
					this.setJobData(jobData);
				} else {
					// navigateTo('/');

					return false;
				}
			}).finally(() => {

				// Hide spinner
				this.loadingSpinner.setParent();
			});
		} else {

			// Load preview job data
			const pathQuery = new URLSearchParams(window.location.search);
			const pjd = pathQuery.get('pjd');
			// const previewJobData = pjd ? JSON.parse(atob(pjd)) : null;
			const previewJobData = pjd ? JSON.parse(Buffer.from(pjd, 'base64').toString('utf-8')) : null;

			if (previewJobData) {
				previewJobData.isPreview = true;
			}

			this.setParent();
			this.setJobData(previewJobData);
		}

		// Hide spinner
		this.loadingSpinner.setParent();
	}

	setJobData(jobData) {
		this.jobData = jobData;

		// Get job values
		const jobPay = jobData && jobData.pay && currencyFormatter.format(jobData.pay) || null;
		const jobRoles = jobData && jobData.roles && formatJobRoles(jobData.roles) || null;

		let jobPostTime = jobData && jobData.postTime || null;

		if (typeof (jobPostTime) !== 'object') {
			jobPostTime = new Date(jobPostTime);
		}

		// Update labels
		this.html.querySelector('.job_details_title').innerHTML = jobData && jobData.title || '?';
		this.html.querySelector('.job_details_roles').innerHTML = jobRoles || '?';
		this.html.querySelector('.job_details_description').innerHTML = jobData && jobData.description || '?';
		this.html.querySelector('.job_details_pay').innerHTML = jobPay || 'To be negotiated';
		// this.html.querySelector('.job_details_poster_name').innerHTML = jobData && jobData.posterName || '?';
		this.html.querySelector('.job_details_poster_time').innerHTML = jobPostTime ? jobPostTime.toLocaleDateString('en-IE') : '?';
		this.html.querySelector('.job_details_poster_time').innerHTML = jobPostTime ? dateFormatter.format(jobPostTime) : '?';

		// Update buttons
		this.applyButton.style.display = jobData.isPreview ? `none` : `block`;
		this.submitButton.style.display = jobData.isPreview ? `block` : `none`;
		this.bookmarkButton.style.display = jobData.isPreview ? 'none' : 'block';
		this.editButton.style.display = jobData.isPreview ? 'block' : 'none';
	}

	setJobDataFromID(jobID) {
		return new Promise((resolve, reject) => {
			getJobFromID(jobID).then((jobData) => {
				this.setJobData(jobData);

				return resolve(jobData);
			}).catch(reject);
		});
	}

	setParent(parentDiv) {
		this.parentDiv = parentDiv;

		if (parentDiv) {
			if (this.html.classList.contains('page')) {
				this.html.classList.remove('page');
			}

			parentDiv.appendChild(this.html);
		} else {
			this.html.remove();

			if (!this.html.classList.contains('page')) {
				this.html.classList.add('page');
			}
		}
	}
}