import './login.css';

import LoginHTML from './login.html';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { attemptLogin } from 'Modules/session.mjs';
import { getPage } from 'Pages/page_index.mjs';

export class LoginPage extends Page {
	constructor() {
		super();

		this.html = createHTML(LoginHTML);
	}

	submitLogin() {
		const now = Date.now();

		if (this.lastLoginTime && (now - this.lastLoginTime) < 1000) {
			return;
		}

		if (this.isLoggingIn) return;
		this.isLoggingIn = true;
		this.lastLoginTime = now;

		const accountInput = this.html.querySelector('#account_input');
		const passwordInput = this.html.querySelector('#password_input');
		const loginButton = this.html.querySelector('#login_button');

		if (!loginButton.classList.contains('disabled')) {
			loginButton.classList.add('disabled');
		}

		attemptLogin(accountInput.value, passwordInput.value).then((response) => {
			console.log('Login response', response);

			navigateTo('/account');
		}).catch((error) => {
			console.warn('Failed to login');
			console.log(error);
		}).finally(() => {
			this.isLoggingIn = false;

			if (loginButton.classList.contains('disabled')) {
				loginButton.classList.remove('disabled');
			}
		});
	}
}

window.submitLoginPanel = function () {
	getPage('login').submitLogin();

	return false;
}