import { getPage, resolvePathSegments } from 'Pages/page_index.mjs';

let activePage;

export async function setActivePage(url) {

	// String to url
	if (typeof (url) === 'string') {
		url = new URL(window.location.origin + url);
	}

	// Get page from path
	const startTime = performance.now();
	const pathSegments = url.pathname.split('/');
	const page = resolvePathSegments(pathSegments);

	if (!page) {
		console.warn(`Failed to navigate to ${url}, page not found`);

		// 404
		// navigateTo();

		return
	}

	// Remove home
	if (pathSegments[1] === 'home') {
		history.replaceState(null, '', '/');
	}

	// Refresh if same
	if (activePage == page) {
		if (activePage.html) {
			activePage.html.remove();
		}

		if (activePage.refresh) {
			activePage.refresh();
		}

		setTimeout(function () {
			document.body.appendChild(activePage.html);

			if (activePage.afterOpened) {
				activePage.afterOpened();
			}
		}, 100 - (performance.now() - startTime));

		return;
	};

	// Hide active page
	if (activePage) {
		if (activePage.close) {
			activePage.close();
		}

		if (activePage.html) {
			activePage.html.remove();
		}
	}

	activePage = page;

	// Show page
	if (activePage) {
		if (activePage.open) {
			const response = await activePage.open();

			if (response === false) {
				return;
			}
		}

		if (activePage.html) {
			setTimeout(function () {
				document.body.appendChild(activePage.html);
			}, 100 - (performance.now() - startTime));
		} else {
			console.warn(`Page ${activePage} has no HTML`);
		}

		if (activePage.afterOpened) {
			activePage.afterOpened();
		}
	}
}

export function navigateTo(path, ignoreHistory) {

	// Validate path
	if (!path) path = '/';

	if (path.charAt(0) !== '/') {
		path = '/' + path;
	}

	// Show page
	const url = new URL(window.location.origin + path);

	if (ignoreHistory) {
		history.replaceState(null, '', url);
	} else {
		history.pushState(null, '', url);
	}

	setActivePage(url);
}

export function navigateLink(element) {
	const href = element.getAttribute('href');

	if (href) {
		navigateTo(href);
	}
}

window.navigateTo = navigateTo;
window.navigateLink = navigateLink;