import { HomePage } from 'Pages/home/home.mjs';
import { ItemListPage } from 'Pages/itemlist/itemlist.mjs';
import { JobPage } from 'Pages/job/job.mjs';
import { UserPage } from 'Pages/user/user.mjs';
import { BookmarksPage } from 'Pages/bookmarks/bookmarks.mjs';
import { AccountPage } from 'Pages/account/account.mjs';
import { LoginPage } from 'Pages/login/login.mjs';
import { SignupPage } from 'Pages/signup/signup.mjs';
import { NewsPage } from 'Pages/news/news.mjs';

let pageCache = {};

export const PAGE_INDEX = {
	'home': HomePage,

	'il': ItemListPage,
	'itemlist': ItemListPage,

	'b': BookmarksPage,
	'bookmarks': BookmarksPage,

	'u': UserPage,
	'user': UserPage,

	'j': JobPage,
	'job': JobPage,
	
	'account': AccountPage,
	'login': LoginPage,
	'signup': SignupPage,
	'news': NewsPage,
}

export function getPage(pageName) {

	// Default page
	if (!pageName) {
		pageName = 'home';
	}

	// Get cached page
	const pageClass = PAGE_INDEX[pageName];
	
	let page = pageCache[pageClass];

	if (!page) {

		// Validate page class
		// const pageClass = PAGE_INDEX[pageName];

		if (!pageClass) {
			console.warn(`Failed to create page ${pageName}, page class not found`);

			return null;
		}

		// Create page
		page = new pageClass();
		pageCache[pageClass] = page;
	}

	return page;
}

export function resolvePathSegments(pathSegments) {
	let page = getPage(pathSegments[1]);

	if (pathSegments.length > 2) {
		for (let key = 2; key < pathSegments.length; key++) {
			const childPageName = pathSegments[key];
			const childPage = page.childPages && page.childPages[childPageName];

			if (childPage) {
				page = childPage;
			} else if (!page.isDynamicPage) {
				console.warn(`Failed to find child page ${childPageName} in ${page}`);

				break;
			}
		}
	}

	return page;
}