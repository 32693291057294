import PageActionHTML from './page_action.html';

import { createHTML } from 'Modules/html.mjs';
import { promptLogin, setupLoginRequiredButton } from 'Modules/session.mjs';
import { navigateLink } from 'Modules/router.mjs';

export const pageActions = {
	newJobOffer: {
		label: 'New job offer',

		setupButton(button) {
			button.removeAttribute('onclick');
			button.setAttribute('href', '/j/n');

			setupLoginRequiredButton(button, 'Log in to create a new job offer');
		},
	},

	viewJobOffers: {
		label: 'My job offers',

		setupButton: function (button) {
			button.removeAttribute('onclick');
			button.setAttribute('href', '/j/offers');

			setupLoginRequiredButton(button, 'Log in to view jobs you have offered');
		},
	},

	viewJobsOffered: {
		label: 'Offers to me',

		setupButton: function (button) {
			button.removeAttribute('onclick');
			button.setAttribute('href', '/j/offered');

			setupLoginRequiredButton(button, 'Log in to view jobs offered to you');
		},
	},

	viewBookmarks: {
		label: 'View bookmarks',

		setupButton(button) {
			button.removeAttribute('onclick');
			
			setupLoginRequiredButton(button, 'Log in to view bookmarks');
		},

		showButton(button) {

			// Set button url to bookmarks for category based on current url
			const pathQuery = new URLSearchParams(window.location.search);
			const category = pathQuery.get('c') || pathQuery.get('category');

			button.setAttribute('href', category ? `/b?c=${category}` : null);
		}
	},
}

export function setPageActions(page, actionNames) {
	page.activePageActions = actionNames;

	// Update action buttons
	const container = page.html.querySelector('.page_actions');

	for (let actionName in pageActions) {

		// Check if action is visible
		const actionData = pageActions[actionName];
		const isVisible = actionNames && actionNames.includes(actionName) || false;

		if (isVisible) {

			// Create button if it doesnt exist
			if (!actionData.button) {
				actionData.button = createHTML(PageActionHTML);
				actionData.button.innerHTML = actionData.label || '?';

				// Fire setup function on button
				if (actionData.setupButton) {
					actionData.setupButton(actionData.button);
				}
			}

			// Fire show function on button
			if (actionData.showButton) {
				actionData.showButton(actionData.button);
			}

			// Show DEEZ NUTZ
			container.appendChild(actionData.button);
		} else {

			// Hide button if it exists
			if (actionData.button) {
				if (actionData.hideButton) {
					actionData.hideButton(actionData.button);
				}

				actionData.button.remove();
			}
		}
	};
}