import './user.css';

import UserHTML from './user.html';
import EditHTML from './edit.html';
import PostHTML from './post.html';

import { decodeJwt } from 'jose';

import { createHTML } from 'Modules/html.mjs';
import { setActiveModal } from 'Modals/modals.mjs';
import { formatJobRoles } from 'Data/roles.mjs';
import { navigateTo } from 'Modules/router.mjs';
import { promptLogin } from 'Modules/session.mjs';
import { Page } from 'Classes/page/page.mjs';
import { Spinner } from 'Classes/spinner/spinner.mjs';

import { UserGalleryPage } from './gallery/gallery.mjs';
import { UserReviewsPage } from './reviews/reviews.mjs';
import { UserPayPage } from './pay/pay.mjs';

const timeFormatter = new Intl.DateTimeFormat('en-IE', {
	month: 'long',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	hour12: true
});

const headerActions = {
	pay: function () {
		const url = window.location;
		const pathSegments = url.pathname.split('/');

		navigateTo(`${pathSegments[1]}/${pathSegments[2]}/pay`);
	},

	bookmark: function (page, button) {

		// Prompt if not logged in
		if (promptLogin({ text: 'Log in to bookmark this person' })) {
			return;
		}

		// Validate user ID
		const userData = page.userData;
		const userID = userData && userData.userID || null;

		if (typeof (userID) !== 'string' || userID == '') {
			console.warn(`Failed to bookmark, invalid user ID`);

			return;
		}

		// Ignore if busy
		if (page.isBookmarking) return;
		page.isBookmarking = true;

		// Disable button
		if (!button.classList.contains('disabled')) {
			button.classList.add('disabled');
		}

		// Request add
		fetch(window.location.origin + `/api/bookmarks/add`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				auth: localStorage.getItem('session_token'),

				category: 'contractors',
				referenceID: userID,
			}),
		}).then((response) => {
			response.json().then((jsonResponse) => {
				if (!jsonResponse) return;

				console.log(jsonResponse);
			}).catch((error) => {
				console.log('failed to decode json');
				console.log(error);
			});
		}).finally(() => {

			// Enable button
			page.isBookmarking = false;

			if (button.classList.contains('disabled')) {
				button.classList.remove('disabled');
			}
		});
	},

	contact: function () {
		console.log('contact this user');
	},

	logout: function () {
		const sessionToken = localStorage.getItem('session_token');

		if (sessionToken) {
			localStorage.removeItem('session_token');

			navigateTo('/');
		}
	},
}

const contactActions = {
	open_map: function () {
		console.log('open_map');
	},

	contact_number: function () {
		// window.location.href = 'tel:+353839998888';
	},

	contact_email: function () {
		// window.location.href = 'mailto:example@example.com';
	},

	open_website: function () {
		// window.open('https://example.com', '_blank');
	},

	open_reviews: function () {
		const url = window.location;
		const pathSegments = url.pathname.split('/');

		navigateTo(`${pathSegments[1]}/${pathSegments[2]}/reviews`);
	},
}

export class UserPage extends Page {
	constructor() {
		super();

		this.html = createHTML(UserHTML);

		this.noPostsLabel = this.html.querySelector('.no_posts_label');

		this.loadingSpinner = new Spinner();
		// this.loadingSpinner.setParent(document.body);

		this.isDynamicPage = true;
		this.childPages = {
			'gallery': new UserGalleryPage(),
			'reviews': new UserReviewsPage(),
			'pay': new UserPayPage(),
		}

		// Header actions
		const headerActionsContainer = this.html.querySelector('.contractor_profile_header_actions');

		for (const button of headerActionsContainer.children) {
			const actionName = button.getAttribute('action');
			const actionData = actionName ? headerActions[actionName] : null;

			if (actionData) {
				button.addEventListener('click', (event) => {
					actionData(this, button);
				});
			} else {
				console.warn(`Header action ${actionName} has no action data`);
			}
		}

		// Contact edit
		const editButton = this.html.querySelector('#contact_edit_button');
		const cancelEditButton = this.html.querySelector('#contact_cancel_button');

		editButton.addEventListener('click', (event) => {
			if (this.isEditing) {
				const userData = this.userData;

				// Get updated user data
				const updateData = {
					description: this.inputFields.description[0].value,
					contactNumber: this.inputFields.contactNumber[0].value,
					contactEmail: this.inputFields.contactEmail[0].value,
					contactSite: this.inputFields.contactSite[0].value,
					contactAddress: this.inputFields.contactAddress[0].value,
				}

				// Clear unchanged data + update selected user data
				for (let key in updateData) {
					const value = updateData[key];

					if (value == userData[key] || (value == '' && !userData[key])) {
						delete updateData[key];
					} else {
						userData[key] = value;
					}
				}

				// Submit update
				if (Object.keys(updateData).length > 0) {

					// Refresh user data but make sure it doesnt block update
					try {
						this.setUserData(userData)
					} catch (error) {
						console.warn(`Failed to refresh user data locally`);
					};

					// Request update
					fetch(window.location.origin + '/api/account/update', {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },

						body: JSON.stringify({
							auth: localStorage.getItem('session_token'),
							updateData: updateData,
						}),
					}).then((response) => {
						response.json().then((jsonResponse) => {
							console.log(jsonResponse);
						}).catch((error) => {
							console.warn(`Failed to update account data`);
							console.log(error);
						})
					});
				} else {
					console.log('No updates to submit');
				}
			}

			this.setIsEditing(!this.isEditing);
		});

		cancelEditButton.addEventListener('click', (event) => {
			this.setIsEditing(false);
		});

		// Contact buttons
		const contactInfoContainer = this.html.querySelector('.contractor_profile_contact_info');

		for (const button of contactInfoContainer.children) {
			const actionName = button.getAttribute('action');
			const actionData = actionName ? contactActions[actionName] : null;

			if (actionData) {
				button.addEventListener('click', actionData);
			} else {
				console.warn(`Contact button ${actionName} has no action data`);
			}
		}

		// Create post
		const createPostButton = this.html.querySelector('#create_post_button');
		const createPostInput = this.html.querySelector('#create_post_input');

		let isPosting = false;

		function enableCreatePostbutton() {
			isPosting = false;

			if (createPostButton.classList.contains('disabled')) {
				createPostButton.classList.remove('disabled');
			}
		}

		createPostButton.addEventListener('click', (event) => {

			// Ignore if posting
			if (isPosting) return;

			// Validate content
			const content = createPostInput.value;

			if (content == '') {
				return;
			}

			// Disable button
			isPosting = true;

			if (!createPostButton.classList.contains('disabled')) {
				createPostButton.classList.add('disabled');
			}

			// Request creation
			fetch(window.location.origin + `/api/posts/create`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					auth: localStorage.getItem('session_token'),

					content: content,
				}),
			}).then(async (response) => {
				response.json().then((jsonResponse) => {
					if (jsonResponse.success) {
						if (document.activeElement !== createPostInput) {
							createPostInput.value = '';
						}

						this.refresh();
					}
				}).catch((error) => {
					console.warn(`Failed to decode json response`);
					console.log(error);
				}).finally(enableCreatePostbutton);
			}).catch((error) => {
				console.warn(`Failed to create post`);
				console.log(error);
			}).finally(enableCreatePostbutton);
		});

		// Gallery
		this.html.querySelector('.contractor_profile_content_panel_view_all').addEventListener('click', (event) => {
			const url = window.location;
			const pathSegments = url.pathname.split('/');

			navigateTo(`${pathSegments[1]}/${pathSegments[2]}/gallery`);
		});
	}

	open() {

		// Dont show unloaded page
		if (!this.html.classList.contains('loading')) {
			this.html.classList.add('loading');
		}

		// Show spinner
		this.loadingSpinner.setParent(document.body);

		// Get descriptor
		const pathSegments = window.location.pathname.split('/');
		const userID = pathSegments[2];
		const descriptor = pathSegments[3];

		if (descriptor === 'reviews') {
			console.log(`View ${userID}'s reviews`);
		} else {

			// Clear descriptor
			if (descriptor) {
				history.replaceState(null, '', `/${pathSegments[1]}/${userID}`);//, '/user/' + userID);
			}

			// Show user data
			this.getUserData(userID);
		}
	}

	close() {
		// console.log('User page was closed');
	}

	refresh() {
		this.getUserData();
	}

	getUserData(userID) {

		// Get userID from url
		if (!userID) {
			const pathSegments = window.location.pathname.split('/');

			userID = pathSegments[2];
		}

		// Check if this is me
		const sessionToken = localStorage.getItem('session_token');
		const sessionData = sessionToken ? decodeJwt(sessionToken) : null;
		const sessionAccountID = sessionData ? sessionData.accountID : null;
		const isSelf = sessionAccountID == userID;

		for (let child of this.html.querySelectorAll('.self_only')) {
			if (isSelf) {
				if (child.classList.contains('hidden')) {
					child.classList.remove('hidden')
				}
			} else {
				if (!child.classList.contains('hidden')) {
					child.classList.add('hidden')
				}
			}
		}

		for (let child of this.html.querySelectorAll('.other_only')) {
			if (isSelf) {
				if (!child.classList.contains('hidden')) {
					child.classList.add('hidden')
				}
			} else {
				if (child.classList.contains('hidden')) {
					child.classList.remove('hidden')
				}
			}
		}

		// Get user data
		const page = this;

		function showPage() {

			// Remove spinner
			page.loadingSpinner.setParent();

			// Show page
			if (page.html.classList.contains('loading')) {
				page.html.classList.remove('loading');
			}
		}

		fetch(window.location.origin + `/api/account/get?id=${userID}`).then(async (response) => {
			response.json().then((jsonResponse) => {
				if (!jsonResponse.success) {
					console.warn(`Failed to get account data, ${jsonResponse.message || '?'}`);

					navigateTo('/contractors', true);

					return
				}

				const userData = jsonResponse.userData;

				if (!userData) {
					console.warn(`Failed to update user page, user data not returned`);

					navigateTo('/contractors', true);

					return
				}

				userData.userID = userID;

				this.setUserData(userData);
			}).catch((error) => {
				console.log('failed to decode response json');
				console.log(response.body);
				console.log(error);
			}).finally(showPage);
		}).catch((error) => {
			console.log('fetch error');
			console.log(error);
		}).finally(showPage);
	}

	setUserData(userData) {
		this.userData = userData;

		// console.log('update user data to', userData);

		// Update header labels
		this.html.querySelector('#user_name').innerHTML = (userData.firstName || '') + ' ' + (userData.lastName || '');
		this.html.querySelector('#user_roles').innerHTML = formatJobRoles(userData.roles);
		this.html.querySelector('#user_description').innerHTML = userData.description || '';

		// Update conact info
		const contactNumberLabel = this.html.querySelector('#user_contact_number');
		contactNumberLabel.innerHTML = userData.contactNumber || 'No contact number';
		contactNumberLabel.setAttribute('href', userData.contactNumber ? `tel:+` + userData.contactNumber : '');

		const contactEmailLabel = this.html.querySelector('#user_contact_email');
		contactEmailLabel.innerHTML = userData.contactEmail || 'No contact email';
		contactEmailLabel.setAttribute('email', userData.contactEmail || null);

		let contactSite = userData.contactSite || null;

		if (contactSite && (!contactSite.startsWith('http://') && !contactSite.startsWith('https://'))) {
			contactSite = 'https://' + contactSite;
		}

		const contactSiteLabel = this.html.querySelector('#user_contact_site');
		contactSiteLabel.innerHTML = userData.contactSite || 'No website';
		contactSiteLabel.setAttribute('href', contactSite || '');

		const contactAddressLabel = this.html.querySelector('#user_contact_address');
		contactAddressLabel.innerHTML = userData.contactAddress || 'No address';
		contactAddressLabel.setAttribute('href', userData.contactAddress ? `https://www.google.com/maps?q=` + userData.contactAddress : '');

		this.html.querySelector('#user_review_count').innerHTML = userData.reviewCount && `${userData.reviewCount} reviews` || 'No reviews';

		// Update posts
		const posts = userData && userData.posts || null;
		const postContainer = this.html.querySelector('.user_content_right');

		let visiblePosts = 0;

		if (!this.postDivs) {
			this.postDivs = [];
		}

		if (posts && this.postDivs.length < posts.length) {
			for (let key = this.postDivs.length; key < posts.length; key++) {
				const postDiv = createHTML(PostHTML);

				this.postDivs.push(postDiv);
			}
		}

		for (let key in this.postDivs) {
			const postDiv = this.postDivs[key];
			const postData = posts && posts[key] || null;

			if (postData) {
				const postTime = new Date(postData.postTime * 1000);
				const formattedPostTime = postTime ? timeFormatter.format(postTime) : null;

				postDiv.querySelector('#user_post_time').innerHTML = formattedPostTime || '?';
				postDiv.querySelector('#user_post_content').innerHTML = postData.content || '?';

				postContainer.appendChild(postDiv);

				visiblePosts += 1;
			} else {
				postDiv.remove();
			}
		}

		if (visiblePosts > 0) {
			this.noPostsLabel.remove();
		} else {
			postContainer.appendChild(this.noPostsLabel);
		}
	}

	setIsEditing(isEditing) {
		isEditing = isEditing ? true : false;

		this.isEditing = isEditing;

		// Update buttons
		const editButton = this.html.querySelector('#contact_edit_button');
		const cancelEditButton = this.html.querySelector('#contact_cancel_button');

		if (isEditing) {

			// Update header buttons
			editButton.innerHTML = 'Save';

			if (cancelEditButton.classList.contains('hidden')) {
				cancelEditButton.classList.remove('hidden');
			}

			// Show input
			if (!this.editHTML) {
				this.editHTML = createHTML(EditHTML);

				this.inputFields = {
					description: [this.editHTML.querySelector('#description_input'), this.html.querySelector('#user_description'), 'description'],
					contactNumber: [this.editHTML.querySelector('#contact_number_input'), this.html.querySelector('#user_contact_number'), 'contactNumber'],
					contactEmail: [this.editHTML.querySelector('#contact_email_input'), this.html.querySelector('#user_contact_email'), 'contactEmail'],
					contactSite: [this.editHTML.querySelector('#contact_site_input'), this.html.querySelector('#user_contact_site'), 'contactSite'],
					contactAddress: [this.editHTML.querySelector('#contact_address_input'), this.html.querySelector('#user_contact_address'), 'contactAddress'],
				}
			}

			// Update input fields
			for (let fieldName in this.inputFields) {
				const fieldData = this.inputFields[fieldName];

				fieldData[0].value = this.userData[fieldName] || ''; // Set current input value
				fieldData[1].style.display = 'none'; // Hide label
				fieldData[1].parentNode.insertBefore(fieldData[0], fieldData[1].nextSibling); // Add input field
			}
		} else {

			// Update header buttons
			editButton.innerHTML = 'Edit';

			if (!cancelEditButton.classList.contains('hidden')) {
				cancelEditButton.classList.add('hidden');
			}

			// Update input fields
			for (let fieldName in this.inputFields) {
				const fieldData = this.inputFields[fieldName];

				fieldData[0].remove(); // Remove input field
				fieldData[1].style.display = 'block'; // Show label
			}
		}
	}
}