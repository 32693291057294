import './login.css';

import LoginModalHTML from './login.html';

import { createHTML } from 'Modules/html.mjs';
import { getModal, setActiveModal, hideModal } from 'Modals/modals.mjs';
import { attemptLogin } from 'Modules/session.mjs';
import { Modal } from "Classes/modal/modal.mjs";

export class LoginModal extends Modal {
	constructor() {
		super();

		this.html = createHTML(LoginModalHTML);
	}

	open(contextData) {
		this.contextData = contextData;

		// Update context label
		const contextLabel = this.html.querySelector('#login_context_label');

		if (contextData && contextData.text) {
			contextLabel.innerHTML = contextData.text;

			if (contextLabel.classList.contains('hidden')) {
				contextLabel.classList.remove('hidden');
			}
		} else {
			if (!contextLabel.classList.contains('hidden')) {
				contextLabel.classList.add('hidden');
			}
		}

		// Open modal
		super.open();
	}

	submitLogin() {
		if (this.isLoggingIn) return;
		this.isLoggingIn = true;

		// Get login elements
		const accountInput = this.html.querySelector('#account_input');
		const passwordInput = this.html.querySelector('#password_input');
		const loginButton = this.html.querySelector('#login_button');

		// Disable login button
		if (!loginButton.classList.contains('disabled')) {
			loginButton.classList.add('disabled');
		}

		// Attempt login
		attemptLogin(accountInput.value, passwordInput.value).then((response) => {
			
			// Validate session token
			const sessionToken = response && response.sessionToken || null;

			if (!sessionToken) {
				console.warn(`Login failed`);
				console.log(response);

				return;
			}

			// Handle response
			const contextData = this.contextData;

			if (contextData && contextData.callback) {
				contextData.callback(sessionToken);
			} else {
				location.reload();
			}
		}).catch((error) => {
			console.warn('Failed to login');
			console.log(error);
		}).finally(() => {
			this.isLoggingIn = false;

			if (loginButton.classList.contains('disabled')) {
				loginButton.classList.remove('disabled');
			}

			hideModal('login');
		});
	}
}

window.submitLoginModal = function () {
	getModal('login').submitLogin();

	return false;
}