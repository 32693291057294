import './bookmarks.css';

import { decodeJwt } from 'jose';

import BookmarksHTML from './bookmarks.html'
import BookmarkItemHTML from './bookmark_item.html'

import { createHTML } from 'Modules/html.mjs';
import { navigateTo } from 'Modules/router.mjs';
import { Page } from 'Classes/page/page.mjs';
import { Spinner } from 'Classes/spinner/spinner.mjs';
import { itemCategoryAliases } from 'Data/itemcategories.mjs';

export class BookmarksPage extends Page {
	constructor() {
		super();

		this.html = createHTML(BookmarksHTML);
		this.pageContent = this.html.querySelector('.page_content');
		this.noBookmarksPanel = this.html.querySelector('#no_bookmarks_panel');
		this.loadingSpinner = new Spinner();
	}

	open() {

		// Redirect to login if not logged in
		const sessionToken = localStorage.getItem('session_token');

		if (!sessionToken) {
			navigateTo(`/login`, true);

			return;
		}

		// Show bookmarks
		const pathQuery = new URLSearchParams(window.location.search);
		const category = pathQuery.get('c') || pathQuery.get('category');

		if (category) {
			this.getBookmarks(category);
		} else {
			navigateTo('/');
		}
	}

	getBookmarks(category) {

		// Resolve alias
		if (itemCategoryAliases[category]) {
			category = itemCategoryAliases[category];
		}

		// Show spinner
		this.loadingSpinner.setParent(this.html);

		if (!this.pageContent.classList.contains('hidden')) {
			this.pageContent.classList.add('hidden');
		}

		// Get account ID
		const sessionToken = localStorage.getItem('session_token');
		const sessionData = sessionToken ? decodeJwt(sessionToken) : null;
		const accountID = sessionData ? sessionData.accountID : null;

		// Get bookmarks
		fetch(window.location.origin + `/api/bookmarks/get?uid=${accountID}&category=${category}`, {
			method: 'GET',
		}).then((response) => {
			response.json().then((jsonResponse) => {
				if (!jsonResponse) return;

				if (jsonResponse.success && jsonResponse.bookmarks) {
					this.setBookmarks(jsonResponse.bookmarks, category);
				}
			}).catch((error) => {
				console.log('failed to decode json');
				console.log(error);
			}).finally(() => {

				// Hide spinner
				this.loadingSpinner.setParent();

				if (this.pageContent.classList.contains('hidden')) {
					this.pageContent.classList.remove('hidden');
				}
			});
		});
	}

	setBookmarks(bookmarks, category) {
		this.bookmarks = bookmarks;
		this.category = category;

		// Update title
		const pageContent = this.html.querySelector('.page_content');
		const pageTitle = this.html.querySelector('.panel_title');
		const displayCategory = itemCategoryAliases[category] || category;

		pageTitle.innerHTML = `BOOKMARKED ${displayCategory.toUpperCase()}`;

		// Create array of bookmark buttons
		if (!this.bookmarkButtons) {
			this.bookmarkButtons = [];
		}

		// Create more buttons if not enough
		if (bookmarks && this.bookmarkButtons.length < bookmarks.length) {
			for (let key = this.bookmarkButtons.length; key < bookmarks.length; key++) {

				// Create button
				const bookmarkButton = createHTML(BookmarkItemHTML);

				bookmarkButton.querySelector('.view_button').addEventListener('click', (event) => {

					// Get bookmark data and view it
					const bookmarkData = this.bookmarks && this.bookmarks[key] || null;
					const category = this.category;

					if (bookmarkData) {
						if (category == 'jobs') {
							navigateTo(`/j/${bookmarkData.referenceID}`);
						} else if (category == 'contractors') {
							navigateTo(`/u/${bookmarkData.referenceID}`);
						} else {
							console.warn(`Unhandled bookmark category ${category}`);
						}
					};
				});

				// Add to array of bookmark buttons
				this.bookmarkButtons.push(bookmarkButton);
			}
		}

		// Update bookmark buttons
		const bookmarkContainer = this.html.querySelector('#bookmark_container');

		let visibleBookmarks = 0;

		for (let key in this.bookmarkButtons) {

			// Get bookmark data
			const bookmarkButton = this.bookmarkButtons[key];
			const bookmarkData = bookmarks && bookmarks[key] || null;

			if (bookmarkData) {

				// Update labels
				bookmarkButton.querySelector('.bookmark_title').innerHTML = bookmarkData.title || '?';
				bookmarkButton.querySelector('.bookmark_content').innerHTML = bookmarkData.content || '?';

				// Show button
				if (!bookmarkButton.parentElement) {
					bookmarkContainer.appendChild(bookmarkButton);
				}

				visibleBookmarks += 1;
			} else {

				// Hide button
				bookmarkButton.remove();
			}
		}

		// Update no bookmarks panel
		if (visibleBookmarks > 0) {
			this.noBookmarksPanel.remove();
		} else {
			this.noBookmarksPanel.innerHTML = `You have no bookmarked ${displayCategory}`;

			pageContent.insertBefore(this.noBookmarksPanel, pageTitle.nextSibling);
		}
	}
}